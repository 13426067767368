define(['app', 'componentHelper'], (app,) => {

  const productName = () => {
    const component = {};

    const _channels = {
      update: 'productName/update'
    };

    const _init = (element) => {
      component.element = element;
      app.subscribe(_channels.update, component.retrieveNewName);
      return component;
    };

    const _retrieveNewName = (childId) => {
      return new Promise((res, rej) => app.ajax.get({
        url: '/' + childId + '.name',
        success: component.successHandler,
        error: component.errorHandler,
      }));
    };

    var _successHandler = function(response) {
      component.replaceProductName(response);
    };

    var _errorHandler = function() {
      console.error(
        'ERROR: Could not retrieve new product name information');
    };

    const _replaceProductName = (response) => {
      var newContainer = document.createElement('div');
      newContainer.innerHTML = response;
      var newElem = newContainer.querySelector('[data-component=productName]');
      var productTitle = newElem.querySelector('[data-product-name=title]');
      var productSubtitle = newElem.querySelector('[data-product-name=subtitle]');

      var productTitleOld = component.element.querySelector('[data-product-name=title]');
      var productSubtitleOld = component.element.querySelector('[data-product-name=subtitle]');

      if (productTitleOld) {
        component.element.removeChild(productTitleOld);
      }

      if (productSubtitleOld) {
        component.element.removeChild(productSubtitleOld);
      }

      if (productTitle) {
        component.element.appendChild(productTitle);
      }

      if (productSubtitle) {
        component.element.appendChild(productSubtitle);
      }

    };

    component.init = _init;
    component.replaceProductName = _replaceProductName;
    component.retrieveNewName = _retrieveNewName;
    component.successHandler = _successHandler;
    component.errorHandler = _errorHandler;

    return component;
  };

  return productName;
});
